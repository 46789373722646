import React from "react";
import { Link } from "gatsby";
import { Layout } from "../components/common";

const NotFoundPage = () => (
    <Layout>
        <div className="container">
            <article className="content" style={{ textAlign: `center` }}>
                <h1 className="content-title">Ups.. d'això no tenim!</h1>
                <p>
                    Error 404
                    <br />
                    Aquesta pàgina no existeix
                </p>
                <h2 className="content-title"></h2>
            </article>
        </div>
    </Layout>
);

export default NotFoundPage;
